import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { DisplayTiny, Small } from '../components/text';
import { navSections } from '../components/navbar';
import { navColorOptions } from '../components/navbar/navColors';
import { renderRichText } from '../utils/termsOfUseTextRenderer';
import { get } from '../utils/requestFunctions';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';

const TopSectionWrapper = styled.div`
  background: ${props => props.theme.color.nbPrimary};
  padding-top: ${props => props.theme.spacing(9)};
  padding-bottom: ${props => props.theme.spacing(16)};
`;

const LastRevisedText = styled(Small).attrs(props => ({
  align: 'center',
  weight: 'medium',
  colored: 'white',
}))`
  letter-spacing: 0.5px;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const GradientBar = styled.div`
  width: 34px;
  height: 2px;
  margin: 0 auto;
  background: ${props => props.theme.gradients.redOrange};
  margin-top: ${props => props.theme.spacing(3)};
`;

const MainWrapper = styled.main`
  display: flex;
  justify-content: center;
  @media ${props => props.theme.breakpoints.md} {
    padding-left: ${props => props.theme.spacing(6)};
    padding-right: ${props => props.theme.spacing(6)};
  }
  @media ${props => props.theme.breakpoints.sm} {
    padding-left: ${props => props.theme.spacing(3)};
    padding-right: ${props => props.theme.spacing(3)};
  }
  @media ${props => props.theme.breakpoints.xs} {
    padding-left: ${props => props.theme.spacing(2)};
    padding-right: ${props => props.theme.spacing(2)};
  }
`;

const TextWrapper = styled.div`
  padding-top: ${props => props.theme.spacing(9)};
  list-style-type: none;
  width: 652px;
  @media ${props => props.theme.breakpoints.md} {
    width: ${props => props.theme.spacing(84)};
  }
  @media ${props => props.theme.breakpoints.sm} {
    width: ${props => props.theme.spacing(65)};
  }
  @media ${props => props.theme.breakpoints.xs} {
    width: 100%;
  }
  & li {
    margin: 0;
    padding: 0;
  }
  & > ol {
    list-style-type: none;
    counter-reset: section;
    & > li {
      position: relative;
      &::before {
        counter-increment: section;
        content: counters(section, '.') '.';
        left: 0;
        top: 0;
        position: absolute;
        font-size: ${props => props.theme.text.large.size};
        line-height: ${props => props.theme.text.large.lineHeight};
        font-weight: 500;
      }
      & > p:first-of-type {
        font-size: ${props => props.theme.text.large.size};
        position: relative;
        left: 2.5ch;
        margin: ${props => props.theme.spacing(3)} 0;
      }
      & > ol {
        list-style-type: none;
        counter-reset: section;
        & > li {
          position: relative;
          &::before {
            counter-increment: section;
            content: counters(section, '.') '.';
            left: 0;
            top: 0;
            position: absolute;
            font-size: ${props => props.theme.text.regular.size};
            line-height: ${props => props.theme.text.regular.lineHeight};
          }
          & > p {
            & > span:first-of-type {
              display: block;
              position: relative;
              left: 4ch;
            }
          }
          & > ol {
            list-style-type: none;
            counter-reset: letter;
            & li {
              position: relative;
              &::before {
                counter-increment: letter;
                content: '(' counter(letter, lower-alpha) ')';
                left: 0;
                top: 0;
                position: absolute;
                font-size: ${props => props.theme.text.regular.size};
                line-height: ${props => props.theme.text.regular.lineHeight};
                font-style: italic;
                color: ${props => props.theme.color.darkText};
              }
              & > p {
                & > i:first-of-type {
                  padding-right: 20px;
                  padding-left: 5ch;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useIsFromUnitedStates = () => {
  const [isFromUnitedStates, setIsFromUnitedStates] = useState(true);

  useEffect(() => {
    get(`https://ipinfo.io/json`).then(ipInfo => {
      if (ipInfo.country === 'US') {
        setIsFromUnitedStates(true);
      } else {
        setIsFromUnitedStates(false);
      }
    });
  }, []);

  return isFromUnitedStates;
};

const TermsOfUseQuery = graphql`
  query TermsOfUseQuery {
    unitedStatesContent: contentfulTermsOfUseUs {
      lastModifiedDate
      termsOfUse {
        json
      }
    }
    worldwideContent: contentfulTermsOfUseNonUs {
      lastModifiedDate
      termsOfUse {
        json
      }
    }
  }
`;

const TermsOfUse = () => {
  const meta = usePageMetaAttributes('termsofuse');
  const isFromUnitedStates = useIsFromUnitedStates();
  const { unitedStatesContent, worldwideContent } = useStaticQuery(TermsOfUseQuery);

  const content = isFromUnitedStates ? unitedStatesContent : worldwideContent;

  return (
    <Layout navColor={navColorOptions.nbPrimary} currentSection={navSections.NONE}>
      <SEO {...meta} />
      <TopSectionWrapper>
        <LastRevisedText>Last revised on: {content.lastModifiedDate}</LastRevisedText>
        <DisplayTiny colored="white" weight="bold" align="center">
          Terms of Use
        </DisplayTiny>
        <GradientBar />
      </TopSectionWrapper>
      <MainWrapper>
        <TextWrapper>{renderRichText(content.termsOfUse.json)}</TextWrapper>
      </MainWrapper>
    </Layout>
  );
};

export default TermsOfUse;
